<template>
    <div class="o-site-container" :class="useMainStore().isMenuOpen ? 'is-locked':''">
        <GlobalOffcanvasMenu v-if="useMainStore().isMenuOpen" />
        <GlobalHeader :searchActive="useMainStore().isSearchOpen" :theme="useMainStore().headerTheme" />
        <slot />
        <GlobalCursor/>
        <GlobalFooter />
    </div>
</template>

<script setup>
    import { onMounted } from 'vue';
    import { useMainStore } from '~/store';

    onMounted(() => {
        window.addEventListener('load', () => {
            setTimeout(() => {
                const banner = document.getElementById('onetrust-banner-sdk');

                if (banner) {
                    banner.classList.add('is-showing');
                }
            }, 1000);
        });

        function getCookie(name) {
            let match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
            return match ? decodeURIComponent(match[2]) : null;
        }
        
        // Check and clear legacy cookie
        let consentCookie = getCookie('OptanonConsent');
        
        if (consentCookie && consentCookie.includes('landingPath=https://www.cisilion.com/')) {
            // Delete legacy OptanonConsent cookie
            document.cookie = 'OptanonConsent=; Max-Age=0; path=/; domain=.cisilion.com';
            console.log('Legacy OptanonConsent cookie removed.');
        }
    });
</script>
